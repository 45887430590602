<template>
  <b-card title="Component Test">
    <h4>Date Range Picker Test</h4>
    <date-range-picker
      v-model="daterange"
    >
      <template #input>
        <h6>hola</h6>
      </template>
    </date-range-picker>
    {{ daterange }}
  </b-card>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import DateRangePicker from '@/components/input/DateRangePicker.vue'

export default {
  components: {
    BCard,
    DateRangePicker,
  },
  data() {
    return {
      daterange: {
        startDate: null,
        endDate: null,
      },
      password: 'katak',
    }
  },
  // mounted() {
  //   this.$swal({
  //     title: 'Hewan, hewan apa yang punya saudara?',
  //     input: 'text',
  //     showCancelButton: true,
  //     confirmButtonText: 'Jawab',
  //     cancelButtonText: 'Batalkan',
  //     customClass: {
  //       confirmButton: 'btn btn-primary',
  //       cancelButton: 'btn btn-outline-warning ml-1',
  //     },
  //     buttonsStyling: false,
  //   }).then(async notes => {
  //     if (notes.value !== this.password) {
  //       this.$router.replace({ name: 'home' })
  //     }
  //   })
  // },
}
</script>
